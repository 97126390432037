import { useState, useEffect, Component, cloneElement } from 'react';
import {
  Table, Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload, Drawer, Space, message, Row, Divider, Modal, UploadFile
} from 'antd';
import { api_call, excel_to_json, import_excel, json_to_excel, useWindowSize } from '../../utils';
import type { ColumnsType } from 'antd/es/table';
import { InboxOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

export interface TableFilterType {
  name: string,
  type: 'combobox' | 'date' | 'search',
  datasource: 'api' | 'data',
  data: any
}
export interface TableActionElement {
  name: string,
  key: string,
  placeholder?: string,
  data?: any,
  uploadUrl?: string,
  apiUrl?: string,
  disabledMe?: boolean,
  apiName?: string,
  type: 'file' | 'text' | 'textarea' | 'number' | 'password' | 'date' | 'datetime' | 'daterange' | 'combobox' | 'comboboxapi' | 'checkbox' | 'switch' | 'radio' | 'treeselect' | 'multiselect' | 'cascade' | 'table',
  validator?: Function,
  table?: any,
  isRequired: boolean,
  customRenderer?: Function,
  isImport?: boolean,
}
export interface TableActionType {
  name?: string,
  category?: string,
  elements: TableActionElement[],
  labels?: {
    view?: string,
    edit?: string,
    add?: string,
    changeToEdit?: string,
    submitEdit?: string,
    submitAdd?: string,
    cancel?: string,
  },
  actions: {
    editUrl: string,
    editMethod: string,
    addUrl: string,
    addMethod: string
  }
}


interface TablePageProp { url: any, headers: ColumnsType<any>, detailform: TableActionType, filters?: TableFilterType[], onChange?: (value: any) => void, value?: any }


const TablePage = (props: TablePageProp) => {
  const [drawer, setDrawer] = useState(null as 'edit' | 'view' | 'add' | null);
  const [isDrawerLoading, setDrawerLoading] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 50;
  const [data, setData] = useState(null as any);
  const [filter, setFilter] = useState(null as any);
  const [drawerData, setDrawerData] = useState(null as any);
  const [apiData, setApiData] = useState({} as any);
  const [tableLoading, setTableLoading] = useState(false);
  const [isEmptyAPI, setIsEmptyAPI] = useState(false);
  const [importModal, setImportModal] = useState(0);
  const [importFile, setImportFile] = useState(null as UploadFile<any> | null);
  const [importResult, setImportResult] = useState(null as any);
  const size = useWindowSize();
  const formid = (props.onChange ? "subform" : "form") + new Date().getTime();
  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const loadData = () => {
    if (props.onChange) {
      setData(props.value)
    } else {
      if (!isEmptyAPI) {
        setTableLoading(true);
        api_call('get', props.url + "?page=" + page + "&limit=" + limit + "&" + (filter || ''), null, null, true).then(rsp => {
          if (!rsp.error) {
            var resData = rsp.data.results;
            if (!resData || resData.length == 0 || resData.length < limit) {
              setIsEmptyAPI(true);
            }
            if (resData && resData.lenth > 0)
              setData([...data, ...resData]);


            setTableLoading(false);
            if (page == 1) {
              props.detailform.elements.forEach(element => {

                if (element.type == 'comboboxapi') {
                  setTableLoading(true);

                  api_call('get', element.apiUrl || '', null, null, true).then(refRes => {
                    if (!refRes.error) {
                      setApiData({ ...apiData, ... { [element.key]: refRes.data } });
                      refRes.data.forEach((d: any) => {
                        resData.filter((a: any) => a[element.key] && a[element.key] == d.id).forEach((a: any) => {
                          a[element.apiName || element.key] = d.name
                        })
                        setData(structuredClone(resData));

                      });
                      setTableLoading(false);

                    } else {
                      message.error(refRes.message);
                    }

                  });
                }

              });
            } else {
              props.detailform.elements.forEach(element => {
                if (element.type == 'comboboxapi') {
                  apiData.forEach((d: any) => {
                    resData.filter((a: any) => a[element.key] && a[element.key] == d.id).forEach((a: any) => {
                      a[element.apiName || element.key] = d.name
                    })
                  });
                }
              });

              setData(structuredClone(resData));
            }
          } else {
            message.error(rsp.message);
          }
        });
      }
    }
  }
  useEffect(() => {
    loadData();

  }, [])
  useEffect(() => {
    const node = document.querySelector<HTMLElement>(".data-table" + formid + " .ant-table-body");
    if (node) {
      node.addEventListener("scroll", () => {
        const perc = (node.scrollTop / (node.scrollHeight - node.clientHeight)) * 100;
        if (perc >= 98 && !isEmptyAPI) {
          setPage(page + 1);
          loadData();
        }
      });
    }
  });
  const onClose = () => {
    setDrawerLoading(false);
    setDrawer(null);
    setDrawerData(null);
  };
  const onSave = () => {
    setDrawerLoading(true);
    if (props.onChange) {
      message.success('child saved!');
      var lsData = (data || [])
      if (drawer == 'add') {
        lsData.push(drawerData);
      }
      setData(structuredClone(lsData))
      props.onChange(lsData);
      onClose();
    } else {
      if (drawer == 'edit') {
        api_call(props.detailform.actions.editMethod, props.detailform.actions.editUrl.replace('{id}', drawerData.id), drawerData, null, true).then(rsp => {

          setDrawerLoading(false);
          if (!rsp.error) {
            message.success('Successfully saved!');
            setData([]);
            setPage(1);
            loadData();
            setDrawer('view');
          } else {
            message.error(rsp.message + " (" + rsp.code + ")");
          }
        });
      } else {
        api_call(props.detailform.actions.addMethod, props.detailform.actions.addUrl, drawerData, null, true).then(rsp => {

          setDrawerLoading(false);
          if (!rsp.error) {
            message.success('Successfully added!');
            setData([]);
            setPage(1);
            loadData();
            setDrawer('view');
          } else {
            message.error(rsp.message + " (" + rsp.code + ")");
          }
        });
      }
    }
  }
  const onImportOK = async () => {
    if (importFile == null) {
      message.error("Please select a file!")
    } else {
      if (!props.onChange) {
        let result = await import_excel(importFile.url || '', props.detailform.category || '', props.detailform.name || '');
        setImportResult(result);
      } else {
        var datajson = excel_to_json(importFile.url || '');
        if (datajson) {
          let lsData = [...data, ...datajson];
          setData(lsData);
          props.onChange(lsData)
        }
        setImportModal(0);
      }
    }
  }
  return <>
    {console.log("data: ", drawerData)}
    <Row justify="end">
      <Space>
        {props.detailform.actions.addUrl && <Button type="primary" onClick={() => { setDrawerData({}); setDrawer('add') }}>
          {props.detailform.labels && props.detailform.labels.submitAdd || 'Add'}
        </Button>}
        {props.detailform.elements.filter(a => a.isImport).length > 0 && <Button type="primary" onClick={() => { setImportModal(1); }}>
          {'Import'}
        </Button>}

      </Space>
    </Row>
    <Divider />
    <Row>
      {drawer && <Drawer title={drawer == 'view' ? (props.detailform.labels && props.detailform.labels.view || "View") : (drawer == 'edit' ? (props.detailform.labels && props.detailform.labels.edit || "Edit") : (props.detailform.labels && props.detailform.labels.add || "Add"))}
        placement="right"
        width={size.width > 900 ? 800 : size.width}
        onClose={onClose} open={drawer != null}
        extra={
          <Space>
            <Button onClick={onClose}>{props.detailform.labels && props.detailform.labels.cancel || 'Cancel'}</Button>
            {drawer == 'add' && <Button type="primary" form={formid} key="submit" htmlType="submit">
              {props.detailform.labels && props.detailform.labels.submitAdd || 'Add'}
            </Button>}
            {drawer == 'edit' && <Button type="primary" onClick={onSave}>
              {props.detailform.labels && props.detailform.labels.submitEdit || 'Save'}
            </Button>}
            {drawer == 'view' && <Button type="primary" onClick={() => setDrawer('edit')}>
              {props.detailform.labels && props.detailform.labels.changeToEdit || 'Edit'}
            </Button>}
          </Space>
        }>
        {isDrawerLoading ? <span>Loading</span> :
          <Form
            onFinish={onSave}
            layout="vertical"
            id={formid}
            onValuesChange={(data, allData) => { setDrawerData(allData); console.log(allData); }}
            initialValues={drawerData}
            disabled={drawer == 'view'}
          >
            {props.detailform.elements.map(item => {
              console.log("item: ", item, "value: ", drawerData[item.key]);

              return <Form.Item key={item.key} label={item.name} name={item.key} valuePropName={item.type == 'checkbox' || item.type == 'switch' ? "checked" : (item.type == 'file' ? "fileList" : undefined)} rules={item.type == 'number' ? [] : [{ required: item.isRequired, warningOnly: false, message: item.name + ' is required' }]}>
                {item.type == 'checkbox' && <Checkbox checked={drawerData[item.key]} >{item.name}</Checkbox>
                  || item.type == 'radio' && <Radio.Group value={drawerData[item.key]} >
                    {item.data.map((iData: any) => <Radio value={iData.id}>{iData.name}</Radio>)}
                  </Radio.Group>
                  || (item.type == 'text' || item.type == 'password') && <Input allowClear placeholder={item.placeholder} type={item.type} />
                  || item.type == 'number' && <InputNumber value={drawerData[item.key]} placeholder={item.placeholder} />
                  || item.type == 'combobox' && <Select value={drawerData[item.key]} >
                    {item.data.map((iData: any) => <Select.Option disabled={item.disabledMe ? (iData.id == drawerData.id) : false} value={iData.id}>{iData.name}</Select.Option>)}
                  </Select>
                  || item.type == 'comboboxapi' && <Select value={drawerData[item.key]} >
                    {apiData[item.key] && apiData[item.key].map((iData: any) => <Select.Option disabled={item.disabledMe ? (iData.id == drawerData.id) : false} value={iData.id}>{iData.name}</Select.Option>)}
                  </Select>
                  || item.type == 'treeselect' && <TreeSelect value={drawerData[item.key]}
                    treeData={item.data}
                  />
                  || item.type == 'cascade' &&
                  <Cascader value={drawerData[item.key]}
                    options={item.data}
                  />
                  || item.type == 'date' && <DatePicker format="DD/MM/YYYY" value={drawerData[item.key]} placeholder={item.placeholder} />
                  || item.type == 'datetime' && <DatePicker format="DD/MM/YYYY HH:mm" showHour={true} showMinute={true} value={drawerData[item.key]} placeholder={item.placeholder} />
                  || item.type == 'daterange' && <RangePicker format="DD/MM/YYYY" value={drawerData[item.key]} />
                  || item.type == 'textarea' && <TextArea rows={6} value={drawerData[item.key]} placeholder={item.placeholder} />
                  || item.type == 'switch' && <Switch checked={drawerData[item.key]} />
                  || item.type == 'table' && cloneElement(
                    item.table,
                    {
                      onChange: (childdata: any) => {
                        console.log("childdata", childdata)
                        drawerData[item.key] = childdata
                      }, value: drawerData[item.key]
                    }
                  )
                  || item.type == 'file' && <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                    <Upload.Dragger name="files" action={item.uploadUrl} multiple={false} maxCount={1}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">Support a single upload.</p>
                    </Upload.Dragger>
                  </Form.Item>}

              </Form.Item>
            })}
          </Form>
        }
      </Drawer>}
      <Table className={'data-table' + formid}
        columns={props.headers.map(a => { a.fixed = !props.onChange && a.fixed; return a; })}
        pagination={{ hideOnSinglePage: true }}
        dataSource={data}
        scroll={{ x: 1500, y: 300, scrollToFirstRowOnChange: false }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => { setDrawer('view'); setDrawerData(record); console.log(record) }, // click row
            onDoubleClick: (event) => { }, // double click row
            onContextMenu: (event) => { }, // right button click row
            onMouseEnter: (event) => { }, // mouse enter row
            onMouseLeave: (event) => { }, // mouse leave row
          };
        }}
        loading={tableLoading}
      />
    </Row>
    <Modal title="Import" open={importModal > 0} onOk={onImportOK} onCancel={() => { setImportModal(0); setImportFile(null); setImportResult(null) }}>
      {importModal == 1 && <>
        <Upload.Dragger name="import-files" multiple={false} maxCount={1} beforeUpload={(file) => {
          const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 'application/vnd.ms-excel';
          if (!isExcel) {
            message.error(`${file.name} is not a supported file (xls or xlsx)`);
          }
          return isExcel || Upload.LIST_IGNORE;
        }} onChange={(info) => {
          console.log(info.fileList);
          if (info.fileList)
            setImportFile(info.fileList[0]);
        }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to import</p>
          <p className="ant-upload-hint">Support a single excel file (xls, xlsx).</p>
        </Upload.Dragger>
        <a onClick={() => { json_to_excel([], 'template-import_' + props.detailform.name + '.xlsx', 'Data', props.detailform.elements.filter(a => a.isImport).map(a => a.name)) }}>
          Click here to download import template.</a>
      </>}
      {importModal == 2 &&
        <Row>
          <h2>Import {importResult.error ? 'Failed!' : 'Successfully!'}</h2>
          <Space>
            <Button onClick={() => { json_to_excel(importResult.result, "import-result_" + props.detailform.name + '.xlsx', 'Result', [...props.detailform.elements.filter(a => a.isImport).map(a => a.name), ...['Result']]) }}>Download result</Button>
            <Button onClick={() => { setImportModal(0); setImportFile(null); setImportResult(null) }}>Close</Button>
          </Space>
        </Row>
      }
    </Modal>
  </>
};
export default TablePage;
