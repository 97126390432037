import TablePage from '../../components/table'
export const Unit = () => {
    return <TablePage url="/settings/unit/paginate" headers={[
        {
            title: 'Id',
            width: 10,
            dataIndex: 'id',
            key: 'id',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Unit name',
            width: 50,
            dataIndex: 'name',
            key: 'name',
            fixed: window.innerWidth > 900 && 'left',
        },

        {
            title: 'Group unit name',
            width: 50,
            dataIndex: 'groupUnit',
            key: 'groupUnit',
        },
        {
            title: 'Base unit name',
            width: 50,
            dataIndex: 'baseUnit',
            key: 'baseUnit',
        },
        {
            title: 'Conversion rate',
            width: 50,
            dataIndex: 'conversionRate',
            key: 'conversionRate',
        }
    ]}
        detailform={
            {
                labels: {
                    add: "Add new unit",
                    submitAdd: "Add unit"
                },
                elements: [
                    {
                        name: "Unit name",
                        key: "name",
                        placeholder: "Input Unit name. Ex: Cubic feet",
                        type: 'text',
                        isRequired: true
                    },
                    {
                        name: "Group unit",
                        key: "groupUnit",
                        placeholder: "Group unit",
                        type: 'combobox',
                        data: [{
                            name: "Quantity",
                            id: "quantity"
                        }, {
                            name: "Weight",
                            id: "weight"
                        }, {
                            name: "Time",
                            id: "time"
                        }, {
                            name: "Volume",
                            id: "volume"
                        }, {
                            name: "Area",
                            id: "area"
                        }, ],
                        isRequired: true
                    },
                    {
                        name: "Base unit",
                        key: "baseUnit",
                        placeholder: "Base unit",
                        type: 'combobox',
                        data: [{
                            name: "Gram",
                            id: "g"
                        }, {
                            name: "Litte",
                            id: "l"
                        }, {
                            name: "Cubic millimeter",
                            id: "mm3"
                        }, {
                            name: "Millimeter",
                            id: "mm"
                        }, {
                            name: "Square millimeter",
                            id: "mm2"
                        },],
                        isRequired: true
                    },
                    {
                        name: "Conversion rate",
                        key: "conversionRate",
                        placeholder: "Conversion rate. Ex: 0.9",
                        type: 'number',
                        isRequired: false
                    }

                ],
                actions: {
                    editUrl: '/settings/unit/detail/{id}',
                    editMethod: 'put',
                    addUrl: '/settings/unit',
                    addMethod: 'post'
                }

            }
        } />
}