import * as xlsx from "xlsx";
import { useEffect, useState } from 'react'
interface IAPIResult {
    code: number,
    message: string,
    data: any,
    error: boolean
}
export const API_BASE_URL = "https://api.spinner.vn/v1";
export const api_call = async (method: string, url: string, body: any = null, headers: any = null, isAuth: boolean = false): Promise<IAPIResult> => {
    headers = headers || {};
    headers["Content-Type"] = "application/json";

    if (isAuth) {
        if (localStorage.getItem("authtoken") == null) {
            document.location.href = "/sign-in"
            return { code: 401, error: true, message: "Unauthorized", data: null }
        }
        headers["X-Spinner-AuthClient"] = document.location.host;
        headers["Authorization"] = "Bearer " + localStorage.getItem("authtoken")
    }
    try {
        var result = await fetch(API_BASE_URL + url, {
            method: method,
            headers: headers,
            body: body && JSON.stringify(body)
        });

        if (result && result.status >= 500) {
            return { code: result.status, error: true, message: "Server error", data: null }
        }
        if (result) {
            if (result.status == 401) {
                document.location.href = "/sign-in"
            }
            var rsp = await result.json();

            if (rsp) {
                return { code: result.status, error: result.status >= 200 && result.status < 300 ? false : true, message: rsp.message, data: rsp }
            }
        }
        return { code: result?.status || 0, error: true, message: "Internal error", data: null }

    } catch {

    }

    return { code: 0, error: true, message: "Error occured", data: null }

}

export const excel_to_json = (filename: string): any => {
    var file = xlsx.readFile(filename);
    var sheet = file.Sheets[0]
    var data = xlsx.utils.sheet_to_json(sheet);
    return data;
}

export const json_to_excel = (data: any, filename: string, sheetname: string = "Data", headers: string[] = []) => {
    const workbook = xlsx.utils.book_new();
    var sheet = xlsx.utils.json_to_sheet(data);
    if (headers && headers.length > 0)
        xlsx.utils.sheet_add_aoa(sheet, [headers], { origin: "A1" });
    xlsx.utils.book_append_sheet(workbook, sheet);
}

export const import_excel = async (filename: string, collection: string, dataname: string, concurency: number = 1) => {
    var data = excel_to_json(filename);
    var results = []
    if (!data || data.lenth == 0) {
        return { error: true, msg: "Data is empty. Check your file formatting!" };
    } else {
        for (var i = 0; i < data.length; i++) {
            let lineData = data[i];
            var rsp = await api_call('post', `/${collection}/${dataname}/import`, lineData, null, true);
            if (rsp.error) {
                data['result'] = 'Error: ' +rsp.code + ' - ' + rsp.message
            } else {
                data['result'] = 'Succesfully imported!'
            }
            results.push(data);
        }
    }
    return { error: false, result: results };
}

export const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}


