import TablePage from '../../components/table'
import { InvoiceProduct } from './InvoiceProduct'
export const Invoice = () => {
    return <TablePage url="/orders/invoice/paginate" headers={[
        {
            title: 'Code',
            width: 10,
            dataIndex: 'code',
            key: 'code',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Order code',
            width: 50,
            dataIndex: 'orderCode',
            key: 'orderCode',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Department name',
            width: 50,
            dataIndex: 'departmentName',
            key: 'departmentName',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Customer name',
            width: 50,
            dataIndex: 'customerName',
            key: 'customerName',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Total cost',
            width: 50,
            dataIndex: 'totalCost',
            key: 'totalCost',
        },
        {
            title: 'Total price',
            width: 50,
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            //fixed: 'left',
        },
        {
            title: 'Seller',
            width: 50,
            dataIndex: 'sellerName',
            key: 'sellerName',
            //fixed: 'left',
        },
        {
            title: 'Creator',
            width: 50,
            dataIndex: 'creatorName',
            key: 'creatorName',
            //fixed: 'left',
        },
        {
            title: 'Total discount',
            width: 50,
            dataIndex: 'totalDiscount',
            key: 'totalDiscount',
            //fixed: 'left',
        },
        {
            title: 'Discount type',
            width: 50,
            dataIndex: 'discountType',
            key: 'discountType',
            //fixed: 'left',
        },
        {
            title: 'Status',
            width: 50,
            dataIndex: 'statusName',
            key: 'statusName',
            //fixed: 'left',
        },
        {
            title: 'Type',
            width: 50,
            dataIndex: 'typeName',
            key: 'typeName',
            //fixed: 'left',
        },
        {
            title: 'OrderDate',
            width: 50,
            dataIndex: 'orderDate',
            key: 'orderDate',
            //fixed: 'left',
        }
    ]}
        detailform={
            {
                labels: {
                    add: "Add new invoice",
                    submitAdd: "Add invoice"
                },
                elements: [
                    {
                        name: "Order",
                        key: "orderId",
                        placeholder: "",
                        type: 'comboboxapi',
                        apiUrl:"/orders/order/search",
                        apiName:"orderName",
                        isRequired: true
                    },
                    {
                        name: "Deal",
                        key: "dealId",
                        placeholder: "",
                        type: 'comboboxapi',
                        apiUrl:"/orders/deal/search",
                        apiName:"dealName",
                        isRequired: true
                    },
                    {
                        name: "Customer",
                        key: "customerId",
                        placeholder: "Input Zone name. Ex: Vietnam",
                        type: 'comboboxapi',
                        apiUrl:"/customers/customer/search",
                        apiName:"customerName",
                        isRequired: true
                    },
                    {
                        name: "Department",
                        key: "departmentId",
                        placeholder: "Select department",
                        type: 'comboboxapi',
                        apiUrl: '/settings/department/all',
                        apiName: 'departmentName',
                        isRequired: false
                    },
                    {
                        name: "Seller",
                        key: "sellerId",
                        type: 'comboboxapi',
                        apiUrl: '/admin/user/all',
                        apiName: 'sellerName',
                        isRequired: false
                    },
                    {
                        name: "creator",
                        key: "creatorId",
                        placeholder: "",
                        type: 'comboboxapi',
                        apiUrl: '/admin/user/all',
                        apiName: 'creatorName',
                        isRequired: false
                    },
                    {
                        name: "Invoice Date",
                        key: "invoiceDate",
                        placeholder: "",
                        type: 'datetime',
                        isRequired: true
                    },
                    {
                        name: "Status",
                        key: "status",
                        placeholder: "",
                        type: 'combobox',
                        data: [
                            {id:"new", name: "New"},
                            {id:"delivering", name: "Delivering"},
                            {id:"canceled", name: "Canceled"},
                            {id:"rejected", name: "Rejected"},
                            {id:"done", name: "Done"},
                        ],
                        isRequired: false
                    },{
                        name: "Products",
                        key: "products",
                        placeholder: "",
                        type: 'table',
                        table: <InvoiceProduct />,
                        isRequired: false
                    }

                ],
                actions: {
                    editUrl: '/settings/department/detail/{id}',
                    editMethod: 'put',
                    addUrl: '/settings/department',
                    addMethod: 'post'
                }

            }
        } />
}