import TablePage from '../../components/table'
export const Department = () => {
    return <TablePage url="/settings/department/paginate" headers={[
        {
            title: 'Id',
            width: 10,
            dataIndex: 'id',
            key: 'id',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Department name',
            width: 50,
            dataIndex: 'name',
            key: 'name',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Parent',
            width: 50,
            dataIndex: 'parentName',
            key: 'parentName',
        },
        {
            title: 'Type',
            width: 50,
            dataIndex: 'type',
            key: 'type',
        }
    ]}
        detailform={
            {
                labels: {
                    add: "Add new department",
                    submitAdd: "Add department"
                },
                elements: [
                    {
                        name: "Department name",
                        key: "name",
                        placeholder: "Input Department name. Ex: Warehouse 1",
                        type: 'text',
                        isRequired: true
                    },
                    {
                        name: "Parent",
                        key: "parentId",
                        placeholder: "parent",
                        type: 'comboboxapi',
                        apiUrl: '/settings/department/all',
                        disabledMe: true,
                        apiName: 'parentName',
                        isRequired: false
                    },
                    {
                        name: "Type",
                        key: "type",
                        placeholder: "type",
                        type: 'combobox',
                        data: [
                            { name: 'Store', id: 'store' },
                            { name: 'Warehouse', id: 'warehouse' },
                            { name: 'Factory', id: 'factory' },
                            { name: 'Department', id: 'department' },
                            { name: 'Area', id: 'area' },
                            { name: 'Zone', id: 'zone' },
                            { name: 'Company', id: 'company' },
                        ],
                        isRequired: true
                    }

                ],
                actions: {
                    editUrl: '/settings/department/detail/{id}',
                    editMethod: 'put',
                    addUrl: '/settings/department',
                    addMethod: 'post'
                }

            }
        } />
}