import TablePage from '../../components/table'
export const InvoiceProduct = () => {
    return <TablePage url="/orders/invoiceproduct/paginate" headers={[
        {
            title: 'Invoice code',
            width: 10,
            dataIndex: 'invoiceCode',
            key: 'invoiceCode',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Product name',
            width: 50,
            dataIndex: 'productName',
            key: 'productName',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Quantity',
            width: 50,
            dataIndex: 'quantity',
            key: 'quantity',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Unit',
            width: 50,
            dataIndex: 'unitName',
            key: 'unitName',
        },
        {
            title: 'Cost',
            width: 50,
            dataIndex: 'cost',
            key: 'cost',
            //fixed: 'left',
        },
        {
            title: 'Price',
            width: 50,
            dataIndex: 'price',
            key: 'price',
            //fixed: 'left',
        },
        {
            title: 'discount',
            width: 50,
            dataIndex: 'discount',
            key: 'discount',
            //fixed: 'left',
        },
        {
            title: 'Discount type',
            width: 50,
            dataIndex: 'discountType',
            key: 'discountType',
            //fixed: 'left',
        },
        {
            title: 'Status',
            width: 50,
            dataIndex: 'statusName',
            key: 'statusName',
            //fixed: 'left',
        }
    ]}
        detailform={
            {
                labels: {
                    add: "Add new invoice product",
                    submitAdd: "Add invoice product"
                },
                elements: [
                    {
                        name: "Price",
                        key: "price",
                        placeholder: "Price",
                        type: 'number',
                        isRequired: false
                    },
                    {
                        name: "Quantity",
                        key: "quantity",
                        placeholder: "Quantity",
                        type: 'number',
                        isRequired: false
                    },{
                        name: "Unit",
                        key: "unitId",
                        placeholder: "",
                        type: 'comboboxapi',
                        apiUrl: '/settings/unit/all',
                        apiName: 'unitName',
                        isRequired: false
                    },{
                        name: "Discount",
                        key: "discount",
                        placeholder: "",
                        type: 'number',
                        isRequired: false
                    },{
                        name: "Discount type",
                        key: "discountType",
                        placeholder: "",
                        type: 'combobox',
                        data:[
                            {id: "percent", name: 'Percent'},
                            {id: "amount", name: 'Amount'}
                        ],
                        isRequired: false
                    },
                    {
                        name: "Status",
                        key: "status",
                        placeholder: "",
                        type: 'combobox',
                        data: [
                            {id:"new", name: "New"},
                            {id:"delivering", name: "Delivering"},
                            {id:"canceled", name: "Canceled"},
                            {id:"rejected", name: "Rejected"},
                            {id:"done", name: "Done"},
                        ],
                        isRequired: false
                    },

                ],
                actions: {
                    editUrl: '/orders/invoiceproduct/detail/{id}',
                    editMethod: 'put',
                    addUrl: '/orders/invoiceproduct',
                    addMethod: 'post'
                }

            }
        } />
}