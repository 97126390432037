
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";

/* Settings */
import {DataFormatting} from "./pages/setting/DataFormatting";
import {Department} from "./pages/setting/Department";
import {Jobtitle} from "./pages/setting/Jobtitle";
import {Unit} from "./pages/setting/Unit";

/* Product */
import { Product } from "./pages/product/Product";
import { ProductCategory } from "./pages/product/ProductCategory";
import { ProductType } from "./pages/product/ProductType";
import { ProductVariant } from "./pages/product/ProductVariant";

/* Order */
 import { Order } from "./pages/order/Order";
 import { Invoice } from "./pages/order/Invoice";

 /* Customer */
 import { Customer } from "./pages/customer/Customer";

 /* Inventory */

import {User} from "./pages/admin/User";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { InventoryTransaction } from "./pages/inventory/InventoryTransaction";
import { Deal } from "./pages/order/Deal";
import POS from "./pages/pos/POS";



function App() {

  return (
    <div className="App">
      <Switch>
        <Route path="/sign-in" exact component={SignIn} />
        <Route exact path="/pos" component={POS} />
        <Main>
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/" component={Home} />
          <Route exact path="/settings/dataformattings" component={DataFormatting} />
          <Route exact path="/settings/departments" component={Department} />
          <Route exact path="/settings/jobtitles" component={Jobtitle} />
          <Route exact path="/settings/units" component={Unit} />
          
          <Route exact path="/admin/users" component={User} />

          <Route exact path="/customers/customers" component={Customer} />

          <Route exact path="/inventories/inventorytransactions" component={InventoryTransaction} />
          <Route exact path="/orders/orders" component={Order} />
          <Route exact path="/orders/invoices" component={Invoice} />
          <Route exact path="/orders/deals" component={Deal} />
          <Route exact path="/products/products" component={Product} />
          <Route exact path="/products/productcategories" component={ProductCategory} />
          <Route exact path="/products/producttypes" component={ProductType} />
          <Route exact path="/products/productvariants" component={ProductVariant} />

          <Route exact path="/tables" component={Tables} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/rtl" component={Rtl} />
          <Route exact path="/profile" component={Profile} />
        </Main>
      </Switch>
    </div>
  );
}

export default App;
