import TablePage from '../../components/table'
export const ProductType = () => {
    return <TablePage url="/products/producttype/paginate" headers={[
        {
            title: 'Id',
            width: 10,
            dataIndex: 'id',
            key: 'id',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Product type name',
            width: 50,
            dataIndex: 'name',
            key: 'name',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Product type description',
            width: 50,
            dataIndex: 'description',
            key: 'description',
            fixed: false,
        }
    ]}
        detailform={
            {
                labels: {
                    add: "Add new product type",
                    submitAdd: "Add product type"
                },
                elements: [
                    {
                        name: "Product type name",
                        key: "name",
                        placeholder: "Input Product type name. Ex: Warehouse 1",
                        type: 'text',
                        isRequired: true
                    },
                    {
                        name: "Description",
                        key: "description",
                        placeholder: "Description",
                        type: 'textarea',
                        isRequired: false
                    }

                ],
                actions: {
                    editUrl: '/products/producttype/detail/{id}',
                    editMethod: 'put',
                    addUrl: '/products/producttype',
                    addMethod: 'post'
                }

            }
        } />
}