import TablePage from '../../components/table'
export const ProductCategory = () => {
    return <TablePage url="/products/productcategory/paginate" headers={[
            {
                title: 'Id',
                width: 10,
                dataIndex: 'id',
                key: 'id',
                fixed: window.innerWidth > 900 && 'left',
            },
            {
                title: 'Name',
                width: 50,
                dataIndex: 'name',
                key: 'name',
                fixed: window.innerWidth > 900 && 'left',
            },
            {
                title: 'Description',
                width: 100,
                dataIndex: 'description',
                key: 'description',
                //fixed: 'left',
            }
        ]}
        detailform={
            {
                labels: {
                    add: "Add new product category",
                    submitAdd: "Add product category"
                },
                elements: [
                    {
                        name: "Category name",
                        key: "name",
                        placeholder: "Input Category name. Ex: Clothes",
                        type: 'text',
                        isRequired: true
                    },
                    {
                        name: "Description",
                        key: "description",
                        placeholder: "Input description.",
                        type: 'textarea',
                        isRequired: false
                    }

                ],
                actions: {
                    editUrl: '/products/productcategory/detail/{id}',
                    editMethod: 'put',
                    addUrl: '/products/productcategory',
                    addMethod: 'post'
                }

            }
        } />
}