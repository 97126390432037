import TablePage from '../../components/table'
export const User = () => {
    return <TablePage url="admin/users" headers={[
        {
            title: 'Id',
            width: 10,
            dataIndex: 'id',
            key: 'id',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Username',
            width: 50,
            dataIndex: 'userName',
            key: 'userName',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Full name',
            width: 50,
            dataIndex: 'fullName',
            key: 'parent',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Jobtitle name',
            width: 50,
            dataIndex: 'jobtitleName',
            key: 'jobtitleName',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Department name',
            width: 50,
            dataIndex: 'departmentName',
            key: 'departmentName',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Phone',
            width: 50,
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            width: 50,
            dataIndex: 'email',
            key: 'email',
        }
    ]}
        detailform={
            {
                labels: {
                    add: "Add new User",
                    submitAdd: "Add User"
                },
                elements: [
                    {
                        name: "Full Name",
                        key: "fullName",
                        placeholder: "Input Full name. Ex: John Smith",
                        type: 'text',
                        isRequired: true
                    },
                    {
                        name: "User login name",
                        key: "userName",
                        placeholder: "User login name. Ex: User1",
                        type: 'text',
                        isRequired: true
                    },
                    {
                        name: "Jobtitle",
                        key: "jobtitleId",
                        placeholder: "Jobtitle",
                        type: 'comboboxapi',
                        apiUrl: '/settings/jobtitles',
                        apiName: 'jobtitleName',
                        isRequired: true
                    },
                    {
                        name: "Department",
                        key: "departmentId",
                        placeholder: "Department",
                        type: 'comboboxapi',
                        apiUrl: '/settings/department',
                        apiName: 'departmentName',
                        isRequired: true
                    },
                    {
                        name: "Joining date",
                        key: "joiningDate",
                        placeholder: "Joining date",
                        type: 'date',
                        isRequired: true
                    },
                    {
                        name: "Phone",
                        key: "phone",
                        placeholder: "Phone",
                        type: 'text',
                        isRequired: false
                    },
                    {
                        name: "Email",
                        key: "email",
                        placeholder: "Email",
                        type: 'text',
                        isRequired: false
                    },

                ],
                actions: {
                    editUrl: '/admin/users/{id}',
                    editMethod: 'put',
                    addUrl: '/admin/users',
                    addMethod: 'post'
                }

            }
        } />
}