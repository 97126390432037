import TablePage from '../../components/table'
export const InventoryTransaction = () => {
    return <TablePage url="/inventories/inventorytransaction/paginate" headers={[
        {
            title: 'Id',
            width: 10,
            dataIndex: 'id',
            key: 'id',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Transaction Type',
            width: 50,
            dataIndex: 'transactionType',
            key: 'transactionType',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Product Name',
            width: 50,
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Changes',
            width: 50,
            dataIndex: 'change',
            key: 'change',
        },
        {
            title: 'Reason',
            width: 50,
            dataIndex: 'reason',
            key: 'reason',
        }
    ]}
        detailform={
            {
                labels: {
                    add: "Add new inventory transaction",
                    submitAdd: "Add inventory transaction"
                },
                elements: [
                    {
                        name: "Transaction type",
                        key: "transactionType",
                        placeholder: "Transaction type",
                        type: 'combobox',
                        data: [{id: "import", name: "Import"},{id: "sell", name: "Sell"},{id: "lostfound", name: "Lost/found"},{id: "correct", name: "Correct"}],
                        isRequired: true
                    },
                    {
                        name: "Product",
                        key: "productId",
                        placeholder: "product",
                        type: 'comboboxapi',
                        apiUrl: '/products/product/all',
                        apiName: 'productName',
                        isRequired: true
                    },
                    {
                        name: "Change",
                        key: "change",
                        placeholder: "Change",
                        type: 'number',
                        isRequired: false
                    },
                    {
                        name: "Reason",
                        key: "reason",
                        placeholder: "Reason",
                        type: 'text',
                        isRequired: false
                    }

                ],
                actions: {
                    editUrl: '/inventories/inventorytransaction/detail/{id}',
                    editMethod: 'put',
                    addUrl: '/inventories/inventorytransaction',
                    addMethod: 'post'
                }

            }
        } />
}