import TablePage from '../../components/table'
import moment from 'moment'
export const Customer = () => {
    return <TablePage url="/customers/customer/paginate" headers={[
        {
            title: 'Id',
            width: 10,
            dataIndex: 'id',
            key: 'id',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Customer name',
            width: 50,
            dataIndex: 'name',
            key: 'name',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Phone',
            width: 50,
            dataIndex: 'phone',
            key: 'phone',
        }
    ]}
        detailform={
            {
                labels: {
                    add: "Add new customer",
                    submitAdd: "Add customer"
                },
                elements: [
                    {
                        name: "Customer name",
                        key: "name",
                        placeholder: "Input Customer name.",
                        type: 'text',
                        isRequired: true
                    },
                    {
                        name: "Phone",
                        key: "phone",
                        placeholder: "Phone",
                        type: 'text',
                        isRequired: true
                    }
                ],
                actions: {
                    editUrl: '/customers/customer/detail/{id}',
                    editMethod: 'put',
                    addUrl: '/customers/customer',
                    addMethod: 'post'
                }

            }
        } />
}