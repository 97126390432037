import TablePage from '../../components/table'
import moment from 'moment'
export const Product = () => {
    return <TablePage url="/products/product/paginate" headers={[
        {
            title: 'Id',
            width: 10,
            dataIndex: 'id',
            key: 'id',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Name',
            width: 50,
            dataIndex: 'name',
            key: 'name',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Category',
            width: 50,
            dataIndex: 'categoryName',
            key: 'categoryName',
        },
        {
            title: 'Unit',
            width: 50,
            dataIndex: 'unitName',
            key: 'unitName',
            //fixed: 'left',
        }
    ]}
        detailform={
            {
                labels: {
                    add: "Add new Product",
                    submitAdd: "Add Product"
                },
                elements: [
                    {
                        name: "Product name",
                        key: "name",
                        placeholder: "Input Product name. Ex: T-Shirt with Spinner logo",
                        type: 'text',
                        isRequired: true
                    },
                    {
                        name: "Description",
                        key: "description",
                        placeholder: "Input Product description",
                        type: 'textarea',
                        isRequired: true
                    },
                    {
                        name: "Price",
                        key: "price",
                        placeholder: "Input Product price",
                        type: 'number',
                        isRequired: false
                    },
                    {
                        name: "Category",
                        key: "categoryId",
                        placeholder: "Category",
                        type: 'comboboxapi',
                        apiName: "categoryName",
                        apiUrl: "/products/productcategory/all",
                        isRequired: true
                    },
                    {
                        name: "Unit",
                        key: "unitId",
                        placeholder: "Unit",
                        type: 'comboboxapi',
                        apiName: "unitName",
                        apiUrl: "/settings/unit/all",
                        isRequired: true
                    }

                ],
                actions: {
                    editUrl: '/products/product/detail/{id}',
                    editMethod: 'put',
                    addUrl: '/products/product',
                    addMethod: 'post'
                }

            }
        } />
}