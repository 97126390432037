import TablePage from '../../components/table'
export const Jobtitle = () => {
    return <TablePage url="/settings/jobtitle/detail" headers={[
        {
            title: 'Id',
            width: 10,
            dataIndex: 'id',
            key: 'id',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Jobtitle name',
            width: 50,
            dataIndex: 'name',
            key: 'name',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Parent name',
            width: 50,
            dataIndex: 'parentName',
            key: 'parentName',
        },
        {
            title: 'Type',
            width: 50,
            dataIndex: 'type',
            key: 'type',
        }
    ]}
        detailform={
            {
                labels: {
                    add: "Add new Jobtitle",
                    submitAdd: "Add Jobtitle"
                },
                elements: [
                    {
                        name: "Jobtitle name",
                        key: "name",
                        placeholder: "Input Jobtitle name. Ex: Warehouse 1",
                        type: 'text',
                        isRequired: true
                    },
                    {
                        name: "Parent",
                        key: "parentId",
                        placeholder: "Base unit",
                        type: 'comboboxapi',
                        disabledMe: true,
                        apiUrl: '/settings/jobtitle/all',
                        apiName: 'parentName',
                        isRequired: false
                    },
                    {
                        name: "Type",
                        key: "type",
                        placeholder: "type",
                        type: 'combobox',
                        data: [
                            {name: 'Employee', id: 'employee'},
                            {name: 'Manager', id: 'manager'}
                        ],
                        isRequired: true
                    }

                ],
                actions: {
                    editUrl: '/settings/jobtitle/detail/{id}',
                    editMethod: 'put',
                    addUrl: '/settings/jobtitle',
                    addMethod: 'post'
                }

            }
        } />
}