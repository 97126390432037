import TablePage from '../../components/table'
import moment from 'moment'
export const DataFormatting = () => {
    return <TablePage url="/settings/dataformatting/paginate" headers={[
        {
            title: 'Id',
            width: 10,
            dataIndex: 'id',
            key: 'id',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Zone name',
            width: 50,
            dataIndex: 'name',
            key: 'name',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Time zone',
            width: 50,
            dataIndex: 'timeZone',
            key: 'timeZone',
        },
        {
            title: 'Money prefix',
            width: 50,
            dataIndex: 'moneyPrefix',
            key: 'moneyPrefix',
            //fixed: 'left',
        },
        {
            title: 'Money postfix',
            width: 50,
            dataIndex: 'moneyPostfix',
            key: 'moneypostfix',
            //fixed: 'left',
        },
        {
            title: 'Decimal Symbol',
            width: 50,
            dataIndex: 'decimalSymbol',
            key: 'decimalSymbol',
            //fixed: 'left',
        },
        {
            title: 'Digit grouping',
            width: 50,
            dataIndex: 'digitGrouping',
            key: 'digitGrouping',
            //fixed: 'left',
        },
        {
            title: 'Negative symbol',
            width: 50,
            dataIndex: 'negativeSymbol',
            key: 'negativeSymbol',
            //fixed: 'left',
        },
        {
            title: 'Mesurement system',
            width: 50,
            dataIndex: 'mesurementSystem',
            key: 'negativeSymbol',
            //fixed: 'left',
        },
        {
            title: 'Date time formatting',
            width: 50,
            dataIndex: 'dateTimeFormat',
            key: 'dateTimeFormat',
            //fixed: 'left',
        },
        {
            title: 'Month format',
            width: 50,
            dataIndex: 'monthFormat',
            key: 'monthFormat',
            //fixed: 'left',
        },
        {
            title: 'Date format',
            width: 50,
            dataIndex: 'dateFormat',
            key: 'dateFormat',
            //fixed: 'left',
        },
        {
            title: 'Time format',
            width: 50,
            dataIndex: 'timeFormat',
            key: 'timeFormat',
            //fixed: 'left',
        }
    ]}
        detailform={
            {
                labels: {
                    add: "Add new zone formatting",
                    submitAdd: "Add zone formatting"
                },
                elements: [
                    {
                        name: "Zone name",
                        key: "name",
                        placeholder: "Input Zone name. Ex: Vietnam",
                        type: 'text',
                        isRequired: true
                    },
                    {
                        name: "Time zone",
                        key: "unit",
                        placeholder: "Time zone",
                        type: 'combobox',
                        data: [
                            { name: "UTC", id: "0" },
                            { name: "GMT +1", id: "1" },
                            { name: "GMT +2", id: "2" },
                            { name: "GMT +3", id: "3" },
                            { name: "GMT +4", id: "4" },
                            { name: "GMT +5", id: "5" },
                            { name: "GMT +6", id: "6" },
                            { name: "GMT +7", id: "7" },
                            { name: "GMT +8", id: "8" },
                            { name: "GMT +9", id: "9" },
                            { name: "GMT +10", id: "10" },
                            { name: "GMT +11", id: "11" },
                            { name: "GMT +12", id: "12" },
                            { name: "GMT -1", id: "-1" },
                            { name: "GMT -2", id: "-2" },
                            { name: "GMT -3", id: "-3" },
                            { name: "GMT -4", id: "-4" },
                            { name: "GMT -5", id: "-5" },
                            { name: "GMT -6", id: "-6" },
                            { name: "GMT -7", id: "-7" },
                            { name: "GMT -8", id: "-8" },
                            { name: "GMT -9", id: "-9" },
                            { name: "GMT -10", id: "-10" },
                            { name: "GMT -11", id: "-11" },
                            { name: "GMT -12", id: "-12" }
                        ],
                        isRequired: true
                    },
                    {
                        name: "Money prefix",
                        key: "moneyPrefix",
                        placeholder: "Money prefix. Ex: $",
                        type: 'text',
                        isRequired: false
                    },
                    {
                        name: "Money postfix",
                        key: "moneyPostfix",
                        placeholder: "Money postfix. Ex: đ",
                        type: 'text',
                        isRequired: false
                    },
                    {
                        name: "Decimal symbol",
                        key: "decimalSymbol",
                        placeholder: "Decimal symbol. Ex: .",
                        type: 'text',
                        isRequired: false
                    },
                    {
                        name: "Digit grouping",
                        key: "digitGrouping",
                        placeholder: "Digit grouping. Ex: ,",
                        type: 'text',
                        isRequired: false
                    },
                    {
                        name: "Negative symbol",
                        key: "negativeSymbol",
                        placeholder: "Negative symbol. Ex: -",
                        type: 'text',
                        isRequired: false
                    },
                    {
                        name: "Mesurement system",
                        key: "mesurementSystem",
                        placeholder: "Mesurement system",
                        type: 'combobox',
                        data: [
                            { name: "Metter", id: "metter" },
                            { name: "Imperial", id: "imperial" }
                        ],
                        isRequired: false
                    },{
                        name: "Month format",
                        key: "monthFormat",
                        placeholder: "Month format",
                        type: 'combobox',
                        data: [
                            { id: "yyyy/MM", name: moment().format("yyyy/MM") },
                            { id: "yyyy-MM", name: moment().format("yyyy-MM") },
                            { id: "MM/yyyy", name: moment().format("MM/yyyy") },
                            { id: "MM-yyyy", name: moment().format("MM-yyyy") },
                        ],
                        isRequired: true
                    },
                    {
                        name: "Date time format",
                        key: "dateTimeFormat",
                        placeholder: "Date time format",
                        type: 'combobox',
                        data: [
                            { id: "yyyy/MM/DD", name: moment().format("yyyy/MM/dd")+ " (yyyy/MM/DD)" },
                            { id: "yyyy-MM-DD", name: moment().format("yyyy-MM-dd")+ " (yyyy-MM-dd)" },
                            { id: "dd/MM/yyyy", name: moment().format("dd/MM/yyyy")+ " (dd/MM/yyyy)" },
                            { id: "dd-MM-yyyy", name: moment().format("dd-MM-yyyy")+ " (dd-MM-yyyy)" },
                            { id: "MM/dd/yyyy", name: moment().format("MM/dd/yyyy")+ " (MM/dd/yyyy)" },
                            { id: "MM-dd-yyyy", name: moment().format("MM-dd-yyyy")+ " (MM-dd-yyyy)" },
                        ],
                        isRequired: true
                    },
                    {
                        name: "Time format",
                        key: "timeFormat",
                        placeholder: "Time format",
                        type: 'combobox',
                        data: [
                            { id: "HH:mm", name: moment().format("HH:mm") },
                            { id: "hh:mm A", name: moment().format("hh:mm A") },
                            { id: "HH-mm", name: moment().format("HH-mm") },
                        ],
                        isRequired: true
                    },

                ],
                actions: {
                    editUrl: '/settings/dataformatting/detail/{id}',
                    editMethod: 'put',
                    addUrl: '/settings/dataformatting',
                    addMethod: 'post'
                }

            }
        } />
}