import TablePage from '../../components/table'
import { DealProduct } from './DealProduct'
export const Deal = () => {
    return <TablePage url="/orders/deal/paginate" headers={[
        {
            title: 'Code',
            width: 10,
            dataIndex: 'name',
            key: 'name',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Department name',
            width: 50,
            dataIndex: 'departmentName',
            key: 'departmentName',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Customer name',
            width: 50,
            dataIndex: 'customerName',
            key: 'customerName',
            fixed: window.innerWidth > 900 && 'left',
        },
        {
            title: 'Total cost',
            width: 50,
            dataIndex: 'totalCost',
            key: 'totalCost',
        },
        {
            title: 'Total price',
            width: 50,
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            //fixed: 'left',
        },
        {
            title: 'Seller',
            width: 50,
            dataIndex: 'sellerName',
            key: 'sellerName',
            //fixed: 'left',
        },
        {
            title: 'Creator',
            width: 50,
            dataIndex: 'creatorName',
            key: 'creatorName',
            //fixed: 'left',
        },
        {
            title: 'Total discount',
            width: 50,
            dataIndex: 'totalDiscount',
            key: 'totalDiscount',
            //fixed: 'left',
        },
        {
            title: 'Discount type',
            width: 50,
            dataIndex: 'discountType',
            key: 'discountType',
            //fixed: 'left',
        },
        {
            title: 'Status',
            width: 50,
            dataIndex: 'statusName',
            key: 'statusName',
            //fixed: 'left',
        },
        {
            title: 'Type',
            width: 50,
            dataIndex: 'typeName',
            key: 'typeName',
            //fixed: 'left',
        },
        {
            title: 'DealDate',
            width: 50,
            dataIndex: 'dealDate',
            key: 'dealDate',
            //fixed: 'left',
        }
    ]}
        detailform={
            {
                labels: {
                    add: "Add new deal",
                    submitAdd: "Add deal"
                },
                elements: [
                    {
                        name: "Customer",
                        key: "customerId",
                        placeholder: "Input Zone name. Ex: Vietnam",
                        type: 'comboboxapi',
                        apiUrl:"/customers/customer/search",
                        apiName:"customerName",
                        isRequired: true
                    },
                    {
                        name: "Department",
                        key: "departmentId",
                        placeholder: "Select department",
                        type: 'comboboxapi',
                        apiUrl: '/settings/department/all',
                        apiName: 'departmentName',
                        isRequired: true
                    },
                    {
                        name: "Seller",
                        key: "sellerId",
                        type: 'comboboxapi',
                        apiUrl: '/admin/user/all',
                        apiName: 'sellerName',
                        isRequired: false
                    },
                    {
                        name: "creator",
                        key: "creatorId",
                        placeholder: "",
                        type: 'comboboxapi',
                        apiUrl: '/admin/user/all',
                        apiName: 'creatorName',
                        isRequired: false
                    },
                    {
                        name: "Deal Date",
                        key: "dealDate",
                        placeholder: "",
                        type: 'datetime',
                        isRequired: true
                    },
                    {
                        name: "Deal end date",
                        key: "dealEndDate",
                        placeholder: "",
                        type: 'datetime',
                        isRequired: false
                    },
                    {
                        name: "Status",
                        key: "status",
                        placeholder: "",
                        type: 'combobox',
                        data: [
                            {id:"new", name: "New"},
                            {id:"processing", name: "Processing"},
                            {id:"manufacturing", name: "Manufacturing"},
                            {id:"fulfilling", name: "Fulfilling"},
                            {id:"canceled", name: "Canceled"},
                            {id:"rejected", name: "rejected"},
                            {id:"done", name: "Done"},
                        ],
                        isRequired: false
                    },{
                        name: "Deal products",
                        key: "products",
                        placeholder: "",
                        type: 'table',
                        table: <DealProduct />,
                        isRequired: false
                    }

                ],
                actions: {
                    editUrl: '/orders/deal/detail/{id}',
                    editMethod: 'put',
                    addUrl: '/orders/deal',
                    addMethod: 'post'
                }

            }
        } />
}